<template>
  <client-only>
    <div
      ref="progressBarRef"
      class="progress-bar-wrapper w-full"
      :class="[
        component.outerWrapper,
        $attrs.class
      ]"
    >
      <component
        :is="tag"
        class="progress-bar flex"
        :class="[
          component.mainWrapper,
          {
            '!mt-[0.5625rem]': stepsIcon && !line && !compact
          }
        ]"
      >
        <template v-if="!compact && !line">
          <component
            :is="tagInner"
            v-for="(step, stepKey) in pageSteps"
            :ref="`step-${stepKey + 1}`"
            :key="stepKey"
            :class="[
              component.items,
              `is-${handleStepStatus(stepKey)}`
            ]"
            class="progress-bar-items"
          >
            <div
              class="progress-bar-bullet-wrapper"
              :class="[
                component.bulletWrapper,
                component.lineStatus[handleStepStatus(stepKey)]
              ]"
            >
              <div
                v-if="step.icon || step.iconComponent"
                class="w-6 h-6 flex items-center justify-center"
                :class="component.bullet"
              >
                <NuxtImg
                  v-if="step.icon"
                  :src="step.icon"
                  :alt="step.label"
                  :width="step?.iconDimensions?.width || 24"
                  :height="step?.iconDimensions?.height || 24"
                  :class="{
                    'opacity-30': handleStepStatus(stepKey) === 'default'
                  }"
                />

                <component
                  :is="step.iconComponent"
                  v-if="step.iconComponent"
                  :class="{
                    'opacity-30': handleStepStatus(stepKey) === 'default'
                  }"
                />
              </div>

              <div
                v-else
                class="progress-bar-bullet text-xs border-1"
                :class="[
                  component.bullet,
                  component.bulletStatus[handleStepStatus(stepKey)]
                ]"
              >
                <IconsCheck
                  v-if="handleStepStatus(stepKey) === 'done' && !numbering"
                  :width="direction === 'row' ? '15px' : '9px'"
                />

                <span
                  v-if="numbering"
                  :class="{
                    'text-[0.5rem] font-bold': numbering
                  }"
                >
                  {{ stepKey + 1 }}
                </span>
              </div>
            </div>

            <div
              :ref="`step-text-${stepKey + 1}`"
              :class="[
                component.text,
                {
                  'whitespace-nowrap': direction === 'row'
                }
              ]"
            >
              <!-- eslint-disable vue/no-v-html -->
              <p v-html="step.label || step"></p>
            </div>
          </component>
        </template>

        <template v-else-if="compact">
          <component :is="tagInner">
            <strong>Step {{ currentStep.index }}</strong> of {{ pageSteps.length }}
          </component>

          <component
            :is="tagInner"
            v-if="currentStep.index"
          >
            &nbsp; - <strong>{{ currentStep.active }}</strong>
          </component>
        </template>

        <component
          :is="tagInner"
          v-else-if="line"
          class="h-full"
          :class="stepBgClass"
          :style="{
            width: `${(currentStep.index / (typeof pageSteps === 'number' ? pageSteps : pageSteps.length)) * 100}%`
          }"
        />
      </component>
    </div>
  </client-only>
</template>

<script setup>
import _uniq from 'underscore/cjs/uniq.js'
import _findKey from 'underscore/cjs/findKey.js'
import _find from 'underscore/cjs/find.js'

defineOptions({
  name: 'AtomsProgressBar',
  inheritAttrs: true
})

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null
  },
  direction: {
    type: String,
    default: 'row',
    validator: value => ['row', 'col', ''].includes(value)
  },

  /*
    Use object format
    {
      label: labelName,
      icon: iconComponent
    }
    and set props stepsIcon to true
    to replace bullet and check icon
  */
  steps: {
    type: [Array, Object, Number],
    default: () => [],
    required: true
  },

  compact: {
    type: Boolean,
    default: false
  },

  line: {
    type: Boolean,
    default: false
  },

  numbering: {
    type: Boolean,
    default: false
  },

  theme: {
    type: String,
    default: 'dark',
    validator: value => !!['dark', 'white'].includes(value)
  },

  gap: {
    type: [Number, String],
    default: ''
  },

  iconsWidth: {
    type: [Number, String],
    default: ''
  },

  stepsIcon: {
    type: Boolean,
    default: false
  },

  stepBgClass: {
    type: String,
    default: 'bg-primary'
  },

  stepBgWrapperClass: {
    type: String,
    default: 'bg-gray-400'
  }
})

defineEmits(['update:modelValue'])

const tag = computed(() => {
  return props.line ? 'div' : 'ul'
})

const tagInner = computed(() => {
  return props.line ? 'div' : 'li'
})

const component = computed(() => {
  /**
   * some styles are inside index.css
   * for dynamic purposes
   */
  const direction = props.direction
  const theme = props.theme
  const numbering = props.numbering
  const stepsIcon = props.stepsIcon
  const compact = props.compact
  const line = props.line

  const gap = (() => {
    if (props.gap) {
      return Number(props.gap.toString().replace(/px/g, ''))
    }

    return getKey(direction, {
      col: stepsIcon ? 72 : 98,
      row: 118
    })
  })()

  const iconsWidth = (() => {
    if (stepsIcon) {
      const iconSize = 24
      const margin = 8

      return iconSize + (margin * 2)
    }

    if (props.iconsWidth) {
      return Number(props.iconsWidth.toString().replace(/px/g, ''))
    }

    return getKey(direction, {
      col: 14,
      row: 20
    })
  })()

  const removeExtras = str => str.replace(/false/g, ' ').replace(/\s\s+/g, ' ')

  const outerWrapper = removeExtras(`
    ${direction === 'row' && 'mx-auto overflow-hidden'}
    ${direction && (!compact && !line) && `is-${direction}`}
    ${numbering && 'has-number'}
    ${compact && 'is-compact flex justify-end'}
    ${line && !compact && 'is-line'}

    ${stepsIcon ? 'has-icon text-xs' : 'text-xs leading-[1.125rem] lg:text-sm'}
    ${theme === 'white' ? 'text-white' : 'text-dark'}
  `)

  const mainWrapper = removeExtras(
    getKey(direction, {
      col: !compact && !line && 'flex-col',
      row: 'flex-row mx-auto'
    }) +
    `
      ${(line || compact) && 'flex-row'}
      ${line && !compact && `w-full h-2 ${props.stepBgWrapperClass} relative`}
    `
  )

  const text = getKey(direction, {
    col: 'flex-grow flex items-center',
    row: 'order-1 flex-grow text-center'
  })

  const items = 'flex ' + getKey(direction, {
    col: 'gap-2',
    row: 'gap-2 flex-col'
  })

  const lineStatus = {
    default: getKey(direction, {
      col: 'before:border-l-1 after:border-l-1',
      row: 'before:border-b-1 after:border-b-1'
    })
  }

  const bulletWrapper = (() => {
    const main = 'grid relative items-center justify-items-center ' +
    getKey(direction, {
      col: '',
      row: 'order-2'
    })

    const common = {
      before: 'before:absolute before:content-[""] before:block before:row-start-1 before:col-start-1 before:z-[1] before:border-0 before:border-dark',
      after: 'after:absolute after:content-[""] after:block after:row-start-1 after:col-start-1 after:z-[1] after:border-0 after:border-dark'
    }

    if (direction === 'col') {
      common.before += ' before:border-l-1'
      common.after += ' after:border-l-1'
    } else {
      common.before += ' before:border-b-1'
      common.after += ' after:border-b-1'
    }

    const before = getKey(direction, {
      col: `${common.before} before:top-0`,
      row: `${common.before} before:left-0`
    })

    const after = getKey(direction, {
      col: `${common.after} after:bottom-0`,
      row: `${common.after} after:right-0`
    })

    return `${main} ${before} ${after}`
  })()

  const bullet = 'row-start-1 col-start-1 flex items-center justify-items-center justify-center relative z-[3] rounded-full text-center'

  const bulletStatus = {
    default: theme === 'white' && numbering
      ? 'bg-transparent border-primary'
      : 'bg-white border-gray-400',
    active: 'bg-primary border-gray-400',
    done: 'bg-dark border-primary'
  }

  return {
    gap,
    iconsWidth,

    outerWrapper,
    mainWrapper,
    text,
    items,
    bulletWrapper,
    bullet,
    bulletStatus,
    lineStatus
  }
})

const pageSteps = computed(() => {
  let steps = props.steps

  if (typeof steps === 'number') {
    return steps
  }
  steps = JSON.parse(JSON.stringify(props.steps))

  return !Array.isArray(steps)
    ? _uniq(Object.values(steps))
    : steps
})

const currentStep = computed(() => {
  const currValue = props.modelValue

  if (!currValue) {
    return {
      index: 0,
      active: null
    }
  }

  const steps = props.steps

  if (typeof steps === 'number') {
    return {
      index: Number(props.modelValue)
    }
  }

  const stepNo = _findKey(steps, value => (value === currValue || value?.label === currValue))

  const index = stepNo?.includes('step')
    ? Number(stepNo.replace(/step/gi, ''))
    : Number(stepNo) + 1

  let stepValue = _find(steps, value => (value === currValue || value?.label === currValue))
  stepValue = props.stepsIcon ? stepValue?.label : stepValue

  return {
    index,
    active: stepValue
  }
})

const progressBarRef = ref(null)

onMounted(async () => {
  await nextTick()

  const root = progressBarRef.value?.firstChild
  root?.style.setProperty('--progress-bar-icon', `${component.value.iconsWidth}px`)
  root?.style.setProperty('--progress-bar-icon-half', `${component.value.iconsWidth / 2}px`)
  root?.style.setProperty('--progress-bar-gap', `${component.value.gap}px`)
  root?.style.setProperty('--progress-bar-gap-half', `${component.value.gap / 2}px`)
  root?.style.setProperty('--progress-bar-steps', pageSteps.value.length)
})

function handleStepStatus (index) {
  index++
  const currStep = currentStep.value.index

  if (!currStep || currStep < index) {
    return 'default'
  }

  if (currStep === index) {
    return 'active'
  }

  if (currStep > index) {
    return 'done'
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
